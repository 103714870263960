import { BehaviorSubject, Observable, catchError, lastValueFrom, map, of, tap } from 'rxjs';

import { Injectable } from '@angular/core';

import { MemoizedObservable } from '@cgib/shared/helper/memoized-observable';
import { UploadFileService } from '@cgib/ui-shared/services/upload-file.service';

@Injectable({
    providedIn: 'root'
})
export class FileSelectService {
    private _uploading$ = new BehaviorSubject<boolean>(false);
    private readonly _fileUrls$ = new BehaviorSubject<string[] | null>(null);
    private readonly _errorMessage$ = new BehaviorSubject<string | null>(null);

    @MemoizedObservable()
    isFileUploading$() {
        return this._uploading$.pipe(tap(value => console.log(value)));
    }
    constructor(private fileHandler: UploadFileService) {}

    @MemoizedObservable()
    errorMessage$() {
        return this._errorMessage$;
    }
    setError(error: string | null) {
        this._errorMessage$.next(error);
    }
    uploadFile$(file: FileList, bucket: string, prefix: string): Observable<string[] | null> {
        console.log('uploadFile$', file, bucket);

        this._errorMessage$.next(null);
        if (file) {
            this._uploading$.next(true);
            return this.fileHandler.uploadfiles$(file, bucket, prefix).pipe(
                catchError(error => {
                    this._errorMessage$.next(error);
                    return of(null);
                }),
                map(urls => urls?.map(obj => obj.key) ?? null),
                tap(urls => {
                    this._fileUrls$.next(urls);
                    this._uploading$.next(false);
                })
            );
        }
        return of(null);
    }

    setFileUrl(url: string) {
        try {
            new URL(url);
        } catch (error) {
            this._errorMessage$.next('Invalid Url Provided');

            return;
        }
        if (
            !url.toLowerCase().includes('.jpg') &&
            !url.toLowerCase().includes('.jpeg') &&
            !url.toLowerCase().includes('.webp') &&
            !url.toLowerCase().includes('.png')
        ) {
            this._errorMessage$.next('Invalid File Type');
            return;
        }
        this._errorMessage$.next(null);
        this._fileUrls$.next([url]);
    }

    async checkAndSetUrl(url: string): Promise<boolean> {
        this._uploading$.next(true);
        this._errorMessage$.next('');
        if (!url) {
            this._uploading$.next(false);
            return false;
        }
        const result = await lastValueFrom(this.fileHandler.checkUrlIsValidImage$(url));
        if (result) {
            this._errorMessage$.next(null);
            this._fileUrls$.next([url]);
        } else {
            this._errorMessage$.next('Invalid File Type');
        }
        this._uploading$.next(false);
        return result;
    }
}

import { tap } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MixpanelEventEnum } from '@cgib/shared/enums/mixpanel-event.enum';
import { DndDirective } from '@cgib/ui-shared/directives/dnd.directive';
import { IndicatorsModule } from '@cgib/ui-shared/modules/indicators/indicators.module';
import { MixpanelModule } from '@cgib/ui-shared/modules/mixpanel/mixpanel.module';
import { UploadFileService } from '@cgib/ui-shared/services/upload-file.service';

import { NotificationComponent } from '../notification/notification.component';
import { FileSelectService } from './file-select.service';

@UntilDestroy()
@Component({
    selector: 'cgib-upload-file-select',
    imports: [CommonModule, MixpanelModule, IndicatorsModule, DndDirective, NotificationComponent],
    templateUrl: './upload-file-select.component.html',
    styleUrls: ['./upload-file-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FileSelectService, UploadFileService]
})
export class UploadFileSelectComponent {
    @Input() showSearchButton = true;
    @Input() showNotification = true;
    @Input({ required: true }) bucket!: string;
    @Input({ required: true }) prefix!: string;

    @Output() notification = new EventEmitter<string>();
    @Output() finishUpload = new EventEmitter<string[]>();
    SELECT_VISUAL_ASSET = MixpanelEventEnum.SELECT_VISUAL_ASSET;
    SELECT_VISUAL_SEARCH_UPLOAD_FILE = MixpanelEventEnum.SELECT_VISUAL_SEARCH_UPLOAD_FILE;
    SELECT_VISUAL_SEARCH_BY_URL = MixpanelEventEnum.SELECT_VISUAL_SEARCH_BY_URL;
    SELECT_VISUAL_SEARCH_URL_FILE = MixpanelEventEnum.SELECT_VISUAL_SEARCH_URL_FILE;

    fileUploading = toSignal(this.fileSelectService.isFileUploading$());
    errorMessage$ = this.fileSelectService.errorMessage$().pipe(
        tap(message => {
            if (message) {
                this.notification.emit(message);
            }
        })
    );

    constructor(private fileSelectService: FileSelectService) {}

    upload(files: FileList) {
        this.fileSelectService
            .uploadFile$(files, this.bucket, this.prefix)
            .pipe(
                tap(urls => {
                    if (urls?.length) {
                        this.finishUpload.emit(urls);
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    async setFileUrl(url: string) {
        const res = await this.fileSelectService.checkAndSetUrl(url);
        if (res) {
            this.finishUpload.emit([url]);
        }
    }

    showErrorMessage(value: string | null) {
        this.fileSelectService.setError(value);
    }
}

import { BehaviorSubject, Observable, forkJoin, map, of, switchMap, throwError } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { S3ObjectDto } from '@cgib/shared/dtos/s3-object.dto';
import { MimeType, getGQLMimeType } from '@cgib/shared/enums/mime-type.enum';
import { VisualSearchGraphqlService } from '@cgib/ui-shared/services/graphql/graphql-visual-search.service';

@Injectable({
    providedIn: 'root'
})
export class UploadFileService {
    private readonly graphql = inject(VisualSearchGraphqlService);
    resData$ = new BehaviorSubject<{ message: string; data: string } | null>(null);
    constructor(private readonly http: HttpClient) {}

    uploadfiles$(
        files: FileList,
        bucket: string,
        prefix: string,
        supportedSuffixes = ['.jpg', '.png', 'jpeg']
    ): Observable<Pick<S3ObjectDto, 'mimeType' | 'name' | 'key'>[]> {
        if (files != null) {
            const uploadRequests = Array.from(files).map(file => {
                console.log(file.size);
                if (
                    (supportedSuffixes.some(suff => ['.jpg', '.jpeg', '.png', '.webp'].includes(suff)) &&
                        file.size > 6_291_456) ||
                    (supportedSuffixes.some(suff => ['.mp4', '.webm'].includes(suff)) && file.size > 30_000_000) ||
                    file.size > 3_000_000_000
                ) {
                    return throwError(
                        () =>
                            new Error(
                                `File size is too big, max size is ${supportedSuffixes.some(suff => ['.jpg', '.jpeg', '.png', '.webp'].includes(suff)) ? '6MB' : '30MB'}`
                            )
                    );
                } else if (!this.isFileTypeSupported(file.name, supportedSuffixes)) {
                    return throwError(() => new Error('File type is not supported'));
                } else {
                    const filename = file.name.replaceAll(' ', '_');
                    return this.graphql.getPresignedUrl$(filename, bucket, prefix, true).pipe(
                        switchMap((url: string) =>
                            this.http
                                .request('PUT', url, {
                                    body: new Blob([file])
                                })
                                .pipe(
                                    map(() => {
                                        return {
                                            key: url.split('?')[0],
                                            name: filename,
                                            mimeType: getGQLMimeType(file.type as MimeType)
                                        };
                                    }) // Return the URL after the file is uploaded
                                )
                        )
                    );
                }
            });
            return forkJoin(uploadRequests);
        }

        return of([]);
    }

    isFileTypeSupported(name: string, supportedSuffixes = ['.jpg', '.png', 'jpeg']): boolean {
        if (supportedSuffixes.some(suffix => name.toLocaleLowerCase().endsWith(suffix))) {
            return true;
        }
        return false;
    }

    checkUrlIsValidImage$(url: string): Observable<boolean> {
        return new Observable<boolean>(observer => {
            // Create new image object
            const img = new Image();
            img.src = url;

            // If the image loads successfully, it is valid
            img.onload = () => {
                observer.next(true);
                observer.complete();
            };

            // If the image fails to load, it is invalid
            img.onerror = () => {
                observer.next(false);
                observer.complete();
            };
        });
    }
}

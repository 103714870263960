<div cgibDnd (fileDropped)="upload($event)" class="relative w-full">
    <cgib-notification
        [message]="errorMessage$ | async"
        class="absolute left-0 top-0 w-full"
        *ngIf="showNotification"
    ></cgib-notification>
    <input
        class="hidden"
        type="file"
        #fileDropRef
        accept="image/png, image/jpeg"
        (change)="upload($any($event.target).files)"
    />
    <div class="w-full space-y-4">
        <div
            class="flex w-full items-center justify-center rounded-2xl border border-dashed border-white/20 bg-black/40 p-4 md:space-x-4"
        >
            <ng-container [ngTemplateOutlet]="dropArea"></ng-container>
        </div>
        <div class="relative">
            <div class="absolute inset-0 flex items-center space-x-8" aria-hidden="true">
                <div class="w-1/2 border-t border-gray-300"></div>
                <div class="w-1/2 border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center">
                <span class="bg-transparent px-2 text-sm text-white">Or</span>
            </div>
        </div>
        <div class="flex w-full items-center justify-center space-x-4">
            <input
                type="input"
                class="w-full rounded-2xl border border-white/20 bg-black/40 px-3 py-1 text-white ring-0 placeholder:text-white/60 focus:outline-none focus:ring-0"
                #imageUrl
                id="imageUrl"
                placeholder="Insert image link"
                (paste)="setFileUrl(imageUrl.value)"
                (keyup)="setFileUrl(imageUrl.value)"
            />
            <button
                *ngIf="showSearchButton"
                class="w-[140px] rounded-md bg-blue-500 px-2 py-1"
                (click)="setFileUrl(imageUrl.value)"
                [mixpanel]="{
                    eventName: SELECT_VISUAL_SEARCH_BY_URL,
                    properties: { origin: 'visual-search' }
                }"
            >
                @if (!fileUploading()) {
                    <div class="whitespace-nowrap">Search Similars</div>
                } @else {
                    <div class="flex space-x-3">
                        <cgib-loading-indicator class="h-6 w-6"> </cgib-loading-indicator>
                        <div>Searching...</div>
                    </div>
                }
            </button>
        </div>
    </div>
</div>
<ng-template #dropArea>
    <button
        class="flex w-full flex-col items-center justify-center space-x-3"
        (click)="fileDropRef.click()"
        [disabled]="fileUploading()"
        [mixpanel]="{
            eventName: SELECT_VISUAL_SEARCH_UPLOAD_FILE,
            properties: { origin: 'visual-search' }
        }"
    >
        @if (!fileUploading()) {
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"></path>
                <path d="M9 15l3 -3l3 3"></path>
                <path d="M12 12l0 9"></path>
            </svg>
            <div>Drop or upload a file</div>
        } @else {
            <div class="flex flex-col items-center justify-center">
                <cgib-loading-indicator class="h-8 w-8"> </cgib-loading-indicator>
                <div>Uploading...</div>
            </div>
        }
    </button>
</ng-template>
